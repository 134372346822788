import { fieldRequiredAsteriskStyles } from '@common/modules/react/themes/components/custom/atoms/FieldRequiredAsterisk';
import {
  InputLabel as MuiInputLabel,
  styled,
  type InputLabelProps as MuiInputLabelProps
} from '@mui/material';
import {
  forwardRef,
  type Ref
} from 'react';

type AxInputLabelProps = MuiInputLabelProps;
// TODO - props
//   - color - remove
//   - disableAnimation - remove
//   - filled - remove
//   - shrink - remove
//   - size - adjust to align with other UI Kit input elements
//   - variant - adjust to align with other UI Kit input elements

const styledOptions = {
  name: 'AxInputLabel'
};

const StyledAxInputLabel = styled(MuiInputLabel, styledOptions)<AxInputLabelProps>(({ theme }) => {
  return {
    color: theme.uiKit.colorGrey70,
    fontSize: theme.uiKit.fontSizeXS,
    '.MuiFormLabel-asterisk': fieldRequiredAsteriskStyles(theme),
    '&.Mui-error': {
      color: theme.uiKit.colorRed60
    }
  };
});

export const AxInputLabel = forwardRef(({
  ...otherProps
}: AxInputLabelProps, ref: Ref<HTMLLabelElement>) => {
  const InputLabelProps: AxInputLabelProps = {
    ...otherProps
  };

  return (
    <StyledAxInputLabel { ...InputLabelProps } ref={ ref } />
  );
});

export default AxInputLabel;
